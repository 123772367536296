import twitter from '@/client/assets/images/twitterIcon.svg'
import linkedIn from '@/client/assets/images/linkedInIcon.svg'
import facebook from '@/client/assets/images/fbIcon.svg'
import email from '@/client/assets/images/outlined-email.svg'
import location from '@/client/assets/images/outlined_location.svg'

const LandingFooter = () => {
  return (
    <section id="varieties" className="relative bg-[#000000]">
      <div className="relative overflow-hidden rounded-lg ">
        <div className="absolute inset-0 bg-[url('@/client/assets/images/landingFooterBg.png')] bg-center bg-no-repeat"></div>
        <div className='mx-auto w-full px-[1rem] xs:px-[2rem] lg:px-[4rem] xl:px-[9rem] py-[5%] lg:py-[7%] flex flex-col gap-[0.6rem] xs:gap-[1.5rem] lg:gap-10'>
          <div className='flex flex-col xs:flex-row justify-between'>
            <h1 className='text-[1.25rem] lg:text-4xl font-semibold w-[100%] xs:w-[40%] lg:w-[30%] my-[20px] xs:mb-0 text-[#FFFFFF]'>{`Land Your Dream Job: Effortless CV Building with`} <span className='text-yellow-300'> {`Rocket Resume`}</span></h1>
            <div className='w-[100%] xs:w-[40%] lg:w-1/5 flex flex-col gap-2 xs:gap-4 lg:gap-8 text-[0.9rem] xs:text-base'>
              <div className='flex items-start gap-2 text-[#edebeb]'>
                <img src={location} alt="location" />
                <p>Apt. 870 18575 Bahringer Canyon, South Traceyburgh, MS 52287</p>
              </div>
              <div className='flex items-start gap-2 text-[#edebeb]'>
                <img src={email} alt="email" />
                <p>support@rocketresume.com</p>
              </div>
            </div>
          </div>
          <div className='w-full border-b border-[#676464]'></div>
          <div className='flex flex-col xs:flex-row justify-between items-center'>
            <div className='flex gap-5'>
              <img src={facebook} alt="facebook" />
              <img src={linkedIn} alt="linkedIn" />
              <img src={twitter} alt="twitter" />
            </div>
            <p className='w-[100%] xs:w-1/5 flex items-center xs:items-start flex-col gap-5 text-sm mt-[20px] xs:mt-0'>© Copyright 2024, All Rights Reserved</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingFooter